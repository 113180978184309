const orderTypes = {
	1: '待审核',
	3: '已接受',
	4: '已拒绝',
	5: '已上传报告',
	6: '已审核报告',
	8: '已支付',
	9: '已评分',
	10: '审核通过',
	11: '审核不通过',
	20: '申请退款',
	21: '已退款',
	30: '已取消',
}
const expertOrderTypes = {
	0: '全部',
	1: '待确认',
	5: '待付款',
	8: '待确认收款',
	9: '待服务',
	11: '待验收',
	15: '待评价',
	20: '已完成',
	99: '取消'
}

const shopType ={
	// 0: '待确认',
	1: '待确认',
	10: '待付款',
	20: '待发货',
	30: '待收货',
	40: '已完成',
	99: '已取消',
}

const leaseType ={
	1: '待确认',
	10: '待付款',
	20: '待发货',
	30: '待收货',
	40: '待还货',
	50: '已还货',
	60: '已完成',
	99: '已取消',
}



export default {
	orderTypes,
	expertOrderTypes,
	shopType,
	leaseType
}
