<template>
	<div>
		<div style="overflow: auto;height:980px;">
			<div v-if="tableData.length > 0">
				<div class="order-item" v-for="(item, i) in tableData" :key="'order' + i">
					<div class="order-item-top">
						<span class="order-item-top-time">下单时间：{{ item.goodsOrder.create_time }}</span>
						<span class="order-item-top-time">订单编号：{{ item.goodsOrder.orderNo }}</span>
					</div>
					<div class="order-item-bottom" v-for="(data, j) in item.goodsOrderSku" :key="j">
						<div class="left">
							<img :src="photoURL + data.image" alt="" />
							<div>
								<div class="title">{{ data.goodsName }}</div>
								<!-- <div> -->
								<div class="margin-right50">品牌：{{ data.brandName }}</div>
								<div>型号：{{ data.number }}</div>
								<!-- </div> -->
							</div>
						</div>
						<div class="center">
							<div class="type">{{ shopTypes[item.goodsOrder.orderStatus] }}</div>
						</div>
						<div class="center">
							<div>{{ data.price == 0 ? '洽谈' : '￥' + data.price }}</div>
							<div>购买数量：{{ data.quantity }}</div>
							<div>付款方式：转账汇款</div>
						</div>
						<div class="center" style="border: none;">
							<div><el-button type="text" @click="openDetail(item.goodsOrder)">查看详情</el-button></div>
							<div><el-button type="text" v-if="item.goodsOrder.orderStatus!=99&&item.goodsOrder.orderStatus!=40" @click="cancelOrder(item.goodsOrder.id)">取消订单</el-button></div>
						</div>
					</div>
				</div>
			</div>
			<el-empty description="没有订单" v-else style="margin-top: 10%;"></el-empty>
		</div>

		<div class="pagination">
			<el-pagination
				style="float: right;"
				@current-change="handleCurrentChange"
				:current-page.sync="parentdata.current"
				:page-size="parentdata.size"
				layout="total, prev, pager, next,jumper"
				:total="tableData.length"
			></el-pagination>
		</div>
		<el-dialog title="订单详情" :visible.sync="dialogOrderVisible" v-if="dialogOrderVisible">
			<div>
				<detail :orderId="orderId" @fatherMethod="fatherMethod"></detail>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import json from '@assets/js/json.js';
import detail from './orderDetail'
export default {
	components:{
		detail
	},
	props: {
		parentdata: {
			type: Object,
			default: () => ({
				size: 20,
				current: 1
			})
		}
	},
	data() {
		return {
			tableData: [],
			shopTypes: [],
			dialogOrderVisible:false,
			orderId:''
		};
	},
	methods: {
		//展示数据
		list() {
			this.$get('/shop-api/mgmt/goods-order/list', this.parentdata).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		openDetail(item){
			this.orderId = item.id;
			this.dialogOrderVisible = true;
		},

		//分页选择页面回调
		handleCurrentChange(val) {
			this.parentdata.current = val;
			this.list();
		},
		fatherMethod(){
			this.list();
		},
		cancelOrder(id){
			this.$prompt('请输入取消订单原因', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			})
				.then(({ value }) => {
					this.$postJson('/shop-api/mgmt/goods-order/cancel', { id: id, reason: value }).then(data => {
						if (data.code != 1000) {
							this.$message.info(data.msg);
							return;
						}
						this.$message.success('订单已取消');
						this.list();
					});
				})
				.catch(() => {});
		}
	},
	mounted() {
		this.list();
		this.shopTypes = json.shopType;
		// console.log(this.shopTypes[this.parentdata]);
	},
	beforeDestroy() {}
};
</script>
<style lang="scss" scoped>
.margin-right50 {
	margin-right: 50px;
}
.order-item {
	width: 98%;
	margin: 10px;
	border: 1px solid #cccccc;
	font-size: 14px;
	.order-item-top {
		padding: 10px;
		background-color: #f9fafc;
		.order-item-top-time {
			margin-right: 100px;
		}
	}

	.order-item-bottom {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border-top: 1px solid #cccccc;
		.left {
			padding: 20px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			min-width: 500px;
			flex: 1;
			border-right: 1px solid #cccccc;
			img {
				width: 120px;
				height: 120px;
				margin-right: 10px;
				border-radius: 10px;
			}
			.title {
				font-size: 16px;
				font-weight: bold;
				line-height: 40px;
				padding-top: 20px;
			}
		}
		.center {
			width: 220px;
			text-align: center;
			font-size: 14px;
			height: 160px;
			border-right: 1px solid #cccccc;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
}
.pagination {
	padding-top: 20px;
}
</style>
