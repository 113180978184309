<template>
	<div>
		<div class="container">
			<div class="all">
				<div class="ensure">
					<div class="left">
						<div class="item flex jc-center ai-center"><img src="../../../../../shoppingMall/img/pendding.png" /></div>
						<h2>{{ handleorderStatus(expertOrderObj.orderStatus) }}</h2>
					</div>
					<div class="right">
						<div class="flex jc-between">
							<div>
								<div>
									<span class="darkColor">订单编号：</span>
									<span class="orderId">{{ expertOrderObj.orderNo }}</span>
									<span class="darkColor">下单时间：</span>
									<span>{{ expertOrderObj.orderTime }}</span>
								</div>
								<div class="payment">
									<span class="darkColor">付款方式：</span>
									<span>转账汇款</span>
								</div>
							</div>
							<div>
								<el-button v-if="expertOrderObj.orderStatus != 40 && expertOrderObj.orderStatus != 99" type="primary" class="cursor" @click="cancelOrder(expertOrderObj.id)">
									取消订单
								</el-button>
								<el-button v-if="expertOrderObj.orderStatus == 1" type="primary" class="cursor" @click="uploadCredentials()">确认订单</el-button>
								<!-- <el-button v-if="expertOrderObj.orderStatus == 30" type="primary" class="cursor" @click="Receive(expertOrderObj.id)">确认收货</el-button> -->
								<el-button v-if="expertOrderObj.orderStatus == 20" type="primary" class="cursor" @click="shipments(expertOrderObj.id)">去发货</el-button>
							</div>
						</div>
						<table class="table">
							<tr>
								<td>付款截止时间</td>
								<td>付款金额</td>
								<td>状态</td>
								<!-- <td>说明</td> -->
							</tr>
							<tr>
								<td>{{ expertOrderObj.payDeadline }}</td>
								<td class="redColor">{{ expertOrderObj.payAmount }}</td>
								<td class="redColor">{{ expertOrderObj.payStatus == 0 ? '未支付' : '已支付' }}</td>
								<!-- <td>{{ expertOrderObj.requireState }}</td> -->
							</tr>
						</table>
					</div>
				</div>
				<!-- 服务信息 -->
				<div class="serverInfo">
					<div class="header">
						<div class="item col1 colText">
							商品信息
							<!-- <span>和我联系</span> -->
						</div>
						<div class="item col2">单价</div>
						<div class="item col3">数量</div>
						<!-- <div class="item col5">小计</div> -->
						<div class="item col6">单价实付</div>
					</div>
					<div class="list" v-for="(item, i) in skuObj" :key="i">
						<div class="item flex col1">
							<div class="flex ai-center p-10">
								<div class="listImg"><img v-if="item.image" :src="photoURL + item.image" /></div>
								<div>
									<div class="brandTop biaoti1">{{ item.goodsName }}</div>
									<div class="brandTop">{{ '品牌：' + item.brandName }}</div>
									<div class="brandTop">{{ '型号：' + item.number }}</div>
								</div>
							</div>
						</div>
						<div class="item col2">{{ item.price == 0 ? '洽谈' : item.price }}</div>
						<div class="item col3">{{ item.quantity }}</div>
						<!-- <div class="item col5">{{ item.price*item.quantity}}</div> -->
						<div class="item col6 blueColor">{{ item.price == 0 ? '洽谈' : item.price }}</div>
					</div>
				</div>
				<!-- 支付方式 -->
				<div class="adress">
					<div class="left" v-if="expertOrderObj.payPath">
						<div class="adressTop">
							<span class="biaoti1">支付方式</span>
							<span class="zhengwen2">目前支持转账汇款</span>
						</div>
						<div class="adressBottom">
							<table>
								<tr>
									<span class="zhengwen2">上传时间：</span>
									<span>{{ expertOrderObj.payDeadline }}</span>
								</tr>
								<tr>
									<span class="zhengwen2">支付凭证：</span>
									<el-image 
									    style="width: 150px; height: 100px"
										fit="contain"
									    :src="photoURL + expertOrderObj.payPath"
									    :preview-src-list="[photoURL + expertOrderObj.payPath]">
									  </el-image>
								</tr>
								
							</table>
						</div>
					</div>
					<div class="right">
						<div class="adressTop"><span class="biaoti1">收货地址</span></div>
						<div class="adressBottom">
							<table>
								<tr>
									<span class="zhengwen2">收 货 人 ：</span>
									<span>{{ expertOrderObj.receiverName }}</span>
								</tr>
								<tr>
									<span class="zhengwen2">联系方式 :</span>
									<span>{{ expertOrderObj.receiverMobile }}</span>
								</tr>
								<tr>
									<span class="zhengwen2">收货地址 :</span>
									<span>{{ expertOrderObj.receiverAddress }}</span>
								</tr>
							</table>

							<!-- </div> -->
						</div>
					</div>
				</div>
				<!-- 需求描述 -->
				<div class="demand">
					<div class="demandTop"><span class="biaoti1">备注：</span></div>
					<div class="content">{{ expertOrderObj.remark }}</div>
				</div>
				<div class="totalPrice">
					<div class="left"></div>
					<div class="right flex ai-center">
						<div>
							<div class="price1">
								<span>运费:</span>
								<span class="blueColor">¥ 0</span>
							</div>
							<div class="price2">
								<span>应付总额:</span>
								<span>¥</span>
								<span class="blueColor words">{{ expertOrderObj.payAmount }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 弹窗 -->
			</div>
		</div>
		<!-- 确认订单修改价格 -->
		<el-dialog title="确认订单信息" :visible.sync="credentialsDia" width="30%" append-to-body v-if="credentialsDia">
			<div class="confirm">
				<div class="list" v-for="(item, i) in skuObj" :key="i">
					<img v-if="item.image" :src="photoURL + item.image" />
					<div>
						<div class="brandTop biaoti1">{{ item.goodsName }}</div>
						<div class="brandTop">{{ '品牌：' + item.brandName }}</div>
						<div class="brandTop">{{ '型号：' + item.number }}</div>
						<div class="brandTop">{{ '数量：' + item.quantity }}</div>
					</div>
					<el-input class="confirmInput" v-model="submitForm.sku[i].price" placeholder="请输入修改后金额"></el-input>
					<div class="tip">价格为0，则为洽谈金额</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="credentialsDia = false">取 消</el-button>
				<el-button type="primary" @click="submit">确认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		orderId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			expertOrderObj: {},
			skuObj: {},
			credentialsDia: false, //弹出窗口
			submitForm: {
				id: '',
				sku: [
					{
						id: '',
						price: ''
					}
				]
			},
		};
	},
	methods: {
		cancelSubimt1() {
			this.getInfo();
		},
		getInfo() {
			this.$get('/shop-api/mgmt/goods-order/get?id=' + this.orderId).then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return;
				}
				this.expertOrderObj = data.data.goodsOrder;
				this.skuObj = data.data.goodsOrderSku;
			});
		},
		handleorderStatus: function(val) {
			switch (val) {
				case 1:
					return '待确认';
				case 10:
					return '待付款';
				case 20:
					return '待发货';
				case 30:
					return '已发货';
				case 40:
					return '已完成';
				case 99:
					return '已取消';
			}
		},
		// 打开确认订单页面
		uploadCredentials() {
			this.submitForm.id = this.expertOrderObj.id;
			this.skuObj.forEach((item, i) => {
				this.submitForm.sku[i].id = item.id;
				this.submitForm.sku[i].price = item.price;
			});
			this.credentialsDia = true;
		},
		// 确认订单
		submit() {
			this.$postJson('/shop-api/mgmt/goods-order/confirm', this.submitForm).then(data => {
				if (data.code != 1000) {
					this.$message.info(data.msg);
					return;
				}
				this.$message.success('确认订单成功');
				this.credentialsDia = false;
				this.submitForm = {};
				this.getInfo();
				this.$emit('fatherMethod');
			});
		},
		//发货
		shipments(id) {
			this.$prompt('请输入物流单号', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			})
				.then(({ value }) => {
					this.$postJson('/shop-api/mgmt/goods-order/deliver', { id: id, deliverNumber: value }).then(data => {
						if (data.code != 1000) {
							this.$message.info(data.msg);
							return;
						}
						this.$message.success('订单发货成功');
						this.getInfo();
						this.$emit('fatherMethod');
					});
				})
				.catch(() => {});
		},
		cancelOrder(id){
			this.$prompt('请输入取消订单原因', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			})
				.then(({ value }) => {
					this.$postJson('/shop-api/mgmt/goods-order/cancel', { id: id, reason: value }).then(data => {
						if (data.code != 1000) {
							this.$message.info(data.msg);
							return;
						}
						this.$message.success('订单已取消');
						this.getInfo();
						this.$emit('fatherMethod');
					});
				})
				.catch(() => {});
		}
	},

	mounted() {
		this.getInfo();
	},
	filters: {
		handleTime: function(val) {
			console.log(val);
			return val.substr(0, 10);
		}
	}
};
</script>

<style lang="scss" scoped>
* {
	letter-spacing: 1px;

	.container {
		.all {
			padding: 0px 20px;

			.ensure {
				display: flex;
				margin: 20px 0;

				.left {
					width: 200px;
					height: 200px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-right: 1px solid #dddddd;

					img {
						width: 80px;
						height: 80px;
					}

					h2 {
						padding: 10px;
						color: #4a99f6;
					}
				}

				.right {
					padding-left: 20px;
					flex: 1;

					.payment {
						padding-top: 20px;
					}

					.orderId {
						padding-right: 20px;
					}

					table {
						width: 100%;
						margin-top: 30px;

						tr {
						}

						td {
							height: 54px;
						}
					}
				}
			}

			.serverInfo {
				.col1 {
					flex: 1;
				}

				.col2 {
					width: 200px;
				}

				.col3 {
					width: 100px;
				}

				.col5 {
					width: 100px;
				}

				.col6 {
					width: 319px;
				}

				.colText {
					text-align: left !important;
					padding-left: 15px;

					span {
						padding-left: 15px;
						color: #4a99f6;
					}
				}

				.header {
					border: 1px solid #bae7ff;
					background-color: #e6f7ff;
					display: flex;
					color: #595959;

					.item {
						text-align: center;
						line-height: 54px;
						height: 54px;
					}
				}

				.list {
					display: flex;

					.item {
						text-align: center;
						height: 148px;
						line-height: 148px;
						border-right: 1px solid #dddddd;
						border-bottom: 1px solid #dddddd;

						&:first-child {
							border-left: 1px solid #dddddd;
						}

						.listImg {
							width: 100px;
							height: 100px;
							margin-right: 15px;

							img {
								border: 1px solid #dddddd;
								border-radius: 10px;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.brandTop {
							height: 30px;
							text-align: left;
							line-height: 30px;
						}
					}
				}
			}

			.adress {
				border-radius: 8px;
				background-color: #efeff4;
				padding: 15px;
				margin-top: 20px;
				display: flex;

				.left {
					width: 50%;
					border-right: 1px solid #dddddd;

					.adressTop {
						height: 40px;
						line-height: 40px;
					}

					.adressBottom {
						table {
							width: 100%;

							tr {
								height: 30px;
								line-height: 30px;
							}
						}
					}
				}

				.right {
					padding-left: 15px;
					flex: 1;

					.adressTop {
						height: 40px;
						line-height: 40px;
					}

					.adressBottom {
						table {
							width: 100%;

							tr {
								height: 30px;
								line-height: 30px;
							}
						}
					}
				}
			}

			.demand {
				border-radius: 8px;
				background-color: #efeff4;
				padding: 15px;
				margin-top: 20px;

				.content {
					// padding: 100px 0;
					padding-top: 10px;
					height: 100px;
				}
			}

			.totalPrice {
				margin: 20px 0;
				border: 1px solid #bae7ff;
				width: 100%;
				height: 154px;
				display: flex;
				background-color: #f2fbff;

				.left {
					flex: 1;
				}

				.right {
					width: 200px;

					.price2 {
						margin-top: 15px;

						.words {
							font-weight: bold;
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	// 表格样式
	.table {
		border-spacing: 0;
		/*去掉单元格间隙*/
		border-top: 1px solid #dddddd;
		border-left: 1px solid #dddddd;

		tr {
			td {
				border-bottom: 1px solid #dddddd;
				border-right: 1px solid #dddddd;
				text-align: center;
			}
		}
	}
}

.top {
	margin-bottom: 22px;

	ul {
		li {
			margin-top: 10px;
			list-style: none;
		}
	}
}
.download {
	padding: 10px 20px;
	color: #409eff;
	cursor: pointer;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
}

::v-deep .el-dialog .el-dialog__header {
	background-color: #f8f8f8;
}

::v-deep .avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 88px;
	height: 88px;
	line-height: 88px;
	text-align: center;
}

.avatar {
	width: 88px;
	height: 88px;
	display: block;
}

.el-select {
	margin-bottom: 10px;
	width: 100%;
}

.confirm {
	width: 100%;
	.list {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	img {
		width: 80px;
		height: 80px;
		border-radius: 6px;
		margin-right: 20px;
	}
	.confirmInput {
		width: 200px;
		margin-left: 20px;
		margin-right: 20px;
	}
	.tip {
		font-size: 12px;
		color: #8c939d;
	}
}

// 组件外的样式
::v-deep .el-textarea .el-textarea__inner {
	background-color: #efeff4 !important;
}
</style>
